import React, { useState } from 'react'
import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/about-us.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactBnbGallery from 'react-bnb-gallery'
import ModalVideo from 'react-modal-video'


import about_us_0 from "../images/about-us/small/0.jpg"
import about_us_1 from "../images/about-us/small/1.jpg"
import about_us_2 from "../images/about-us/small/2.jpg"
import about_us_3  from "../images/about-us/small/3.jpg"
import about_us_4  from "../images/about-us/small/4.jpg"
import about_us_5  from "../images/about-us/small/5.jpg"
import about_us_6  from "../images/about-us/small/6.jpg"
import about_us_7  from "../images/about-us/small/7.jpg"
import about_us_8  from "../images/about-us/small/8.jpg"
import about_us_9  from "../images/about-us/small/9.jpg"
import about_us_10  from "../images/about-us/small/10.jpg"
import about_us_11  from "../images/about-us/small/11.jpg"
import about_us_12  from "../images/about-us/small/12.jpg"
import about_us_13  from "../images/about-us/small/13.jpg"
import about_us_14  from "../images/about-us/small/14.jpg"
import about_us_15  from "../images/about-us/small/15.jpg"
import about_us_16  from "../images/about-us/small/16.jpg"

import big_about_us_1 from "../images/about-us/big/1.jpg"
import big_about_us_2 from "../images/about-us/big/2.jpg"
import big_about_us_3  from "../images/about-us/big/3.jpg"
import big_about_us_4  from "../images/about-us/big/4.jpg"
import big_about_us_5  from "../images/about-us/big/5.jpg"
import big_about_us_6  from "../images/about-us/big/6.jpg"
import big_about_us_7  from "../images/about-us/big/7.jpg"
import big_about_us_8  from "../images/about-us/big/8.jpg"
import big_about_us_9  from "../images/about-us/big/9.jpg"
import big_about_us_10  from "../images/about-us/big/10.jpg"
import big_about_us_11  from "../images/about-us/big/11.jpg"
import big_about_us_12  from "../images/about-us/big/12.jpg"
import big_about_us_13  from "../images/about-us/big/13.jpg"
import big_about_us_14  from "../images/about-us/big/14.jpg"
import big_about_us_15  from "../images/about-us/big/15.jpg"
import big_about_us_16  from "../images/about-us/big/16.jpg"
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';


const AboutUsPage = () => {
  const [galleryOpened, setGalleryOpened] = useState(false)
  const [activePhotoIndex, setActivePhotoIndex] = useState(0)
  const [videoIsOpen, setVideoIsOpen] = useState(false)
  
  return (
    <Layout sidebarComponent={<BookingForm colWidth="col-md-12" />}>
        
      <SEO title="About Riad Chebbi" />
  
      <PageBanner image={banner_image} title="About Riad Chebbi"/>
  
      <div className="inner-content">
  
        <div className="page-text" >
          <p>Welcome to a spectacular desert paradise. Located in the middle of the dunes, the Riad Chebbi is an ideal choice for those who want to explore the Sahara desert of Morocco and more specifically the desert of Merzouga. This oasis of tranquility combines rustic charm, hospitality and luxury to bring to life an authentic desert experience.</p>
          <p></p>
        </div>
  
        <div className="mt-5 mb-5">
          <h2 className="sub-title">Location</h2>
          <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3417.2867208716134!2d-4.014036484467553!3d31.073950576157472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd973d39cb8b0cc5%3A0x2b18b95ebfc84eb4!2sRiad+Chebbi!5e0!3m2!1sen!2sma!4v1565778443180!5m2!1sen!2sma" width="100%" height="400" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
        </div>

        <ModalVideo channel='vimeo' autoplay={true} isOpen={videoIsOpen} videoId='352990995' onClose={() => setVideoIsOpen(false)} />

        <div className="mt-5 mb-5">
          <h2 className="sub-title">Gallery</h2>
          <ul className="image-gallery about-us-gallery columns-3">
            <li class="video-wrapper" onClick={() => setVideoIsOpen(true)}>
              <div className="video-btn"><FontAwesomeIcon icon={faPlayCircle} /></div>
              <img src={about_us_0} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(1-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_1} alt=""/></li>
            <li>
              <div onClick={() => { setActivePhotoIndex(2-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_2} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(3-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_3} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(4-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_4} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(5-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_5} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(6-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_6} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(7-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_7} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(8-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_8} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(9-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_9} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(10-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_10} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(11-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_11} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(12-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_12} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(13-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_13} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(14-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_14} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(15-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_15} alt=""/>
            </li>
            <li>
              <div onClick={() => { setActivePhotoIndex(16-1); setGalleryOpened(true)} } className="zoom-btn"><FontAwesomeIcon icon={faSearchPlus} /></div>
              <img src={about_us_16} alt=""/>
            </li>
          </ul>
        </div>
  
        
        <ReactBnbGallery
          show={galleryOpened}
          photos={[
            { photo: big_about_us_1, thumbnail: about_us_1 },
            { photo: big_about_us_2, thumbnail: about_us_2 },
            { photo: big_about_us_3, thumbnail: about_us_3 },
            { photo: big_about_us_4, thumbnail: about_us_4 },
            { photo: big_about_us_5, thumbnail: about_us_5 },
            { photo: big_about_us_6, thumbnail: about_us_6 },
            { photo: big_about_us_7, thumbnail: about_us_7 },
            { photo: big_about_us_8, thumbnail: about_us_8 },
            { photo: big_about_us_9, thumbnail: about_us_9 },
            { photo: big_about_us_10, thumbnail: about_us_10 },
            { photo: big_about_us_11, thumbnail: about_us_11 },
            { photo: big_about_us_12, thumbnail: about_us_12 },
            { photo: big_about_us_13, thumbnail: about_us_13 },
            { photo: big_about_us_14, thumbnail: about_us_14 },
            { photo: big_about_us_15, thumbnail: about_us_15 },
            { photo: big_about_us_16, thumbnail: about_us_16 },
          ]}
          onClose={() => setGalleryOpened(false)}
          activePhotoIndex={activePhotoIndex}
        />
      
      </div>
  
    </Layout>
  ) 
}

export default AboutUsPage 